import React from 'react';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

const text = 
`

Song of the day (_Canción del día_): _Abstract_ by Hozier

La agenda de hoy:

- Terminar Unidad 8 y repasarla

*******************************************************************************

## Repaso

| Profesión | Profession |
| ---- | -----|
| _vendedor_ | salesperson |
| _mecánico_ | mechanic |
| _mesero_ | server |
| _recepcionista_ | receptionist |
| _cajero_ | cashier | 
| _plomero_ | plumber |
| _conductor de autobús_ | bus driver |
| _pintor_ | painter |
| _ama de casa_ | homemaker |
| _ayudante del profesor_ | teacher's aide |
| _conserje_ | custodian |

| Sentence | _Traducción_ | Profession |
| ---- | ---- | ---- |
| He fixes cars | _Él arregla carros_ | mechanic |
| He serves food | _Él sirve comida_ | server |
| She sells clothes | _Ella vende ropa_ | salesperson |
| He cleans buildings | _Él limpia edificios_ | custodian |
| She answers the phone | _Ella contesta el teléfono_ | receptionist|
| She counts money | _Ella cuenta dinero_ | cashier |

| Verb | _Verbo_ |
| ----- | ----- |
| answer | _contestar_ | 
| count | _contar_ |
| fix | _reparar_ |
| clean | _limpiar_ |
| sell | _vender_ |
| serve | _servir_ |

- Ellos sirven comida = They serve food
- Ellos reparan carros = They fix cars
- Yo cuento dinero = I count money
- Yo limpio edificios = I clean buildings
- Tú vendes ropa = You sell clothes

*******************************************************************************
`

export default function Week02() {
	return (
		<div>
			<h1>Week 11 Lecture Notes (11/14)</h1>

			<ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} children={text} />,
			{/* <h2>Recursos</h2> */}
			{/* <p> */}
			{/* 	<ul> */}
			{/* 		<li><a href="https://quizlet.com/363046894/la-ropa-ingles-flash-cards/">Quizlet sobre la ropa</a></li> */}
			{/* 	</ul> */}
			{/* </p> */}
		</div>
	)
}
