import React from 'react';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

const text = 
`

Song of the day (_Canción del día_): _For Good_ by Stephen Schwartz, Kristin Chenoweth, Idina Menzel

La agenda de hoy:

- Comenzar la Unidad 9

***********************************************************
## Present Progressive

- Presente progresivo = present progressive
	- Estar + participio presente
	- participio presente: corriendo, leyendo, oyendo = -ando/-iendo
	- « to be » + participle
	- participios terminan con -ing (running, reading, hearing)

## Formar el participio presente
- eat (comer) - eating
- walk (caminar) - walking
- cook (cocinar) - cooking
### Excepciones
- Una « e » muda - hay que quitarla antes de añadir el sufijo
	- write - writing
	- make - making
	- bike - biking
- Si tiene una sílaba y termina en consonante-vocal-consonante - dupliquen la última letra
	- cut (cortar) - cutting
	- stop (parar) - stopping
	- run (correr) - running
	- get - getting
	- Excepciones - si la palabra termina en x, w, y - solamente agreguen el sufijo
		- buy (comprar) - buying
		- draw (dibujar) - drawing
		- tow (remolcar) - towing
		- tax (cobrar impuestos) - taxing
- Si termina en una c, agreguen una k 
	- panic (aterrorizarse) - panicking
- Verbos que terminan en « ie »: reemplacen « ie » con « y »
 	- tie (atar) -  tying
	- lie (mentir) - lying

#### Repaso de « to be » ser/estar

|Subject | Conjugation |
| ---|----|
|I | am|
|You| are|
|He |is|
|She |is|
|We| are|
|They| are|

- Yo estoy escribiendo = I am writing
- Él está leyendo = He is reading
***********************************************************

## Una e muda = Silent "e"
- La "e" al fin no se pronuncia
- Modifica la pronunciación de la vocal que inmediatamente precede la "e"

Vocales largas:
- a: bake (hornear)
- e: cede (ceder)
- i: bike (bicicleta)
- o: hope (esperanza)
- u: rebuke (reprochar)

***********************************************************
### More verbs
- Verb Forms
	- Base form (diccionario) (wash = lavar)
	- Infinitive (to walk = lavar)
		- Quiero lavar los platos = I want __to wash__ the dishes
	- Conjugation (washes = lava)
		- Él lava los platos = He washes the dishes

### Quehaceres = Chores
| Infinitive | Present progressive | _Traducción_| 
|----| ----| ----|
| to do homework | doing homework | _hacer tarea_ |
| to do the laundry | doing the laundry | _lavar la ropa_ |
| to dry the dishes | drying the dishes | _secar los platos_ |
| to make lunch | making lunch | _preparar el almuerzo_ | 
| to make the bed | making the bed | _arreglar la cama_ |
| to cut the grass | cutting the grass | _cortar el pasto_ |
| to get the mail | getting the mail | _recoger el correo_ |
| to take out the trash | taking out the trash | _sacar la basura_ |
| to walk the dog | walking the dog | _pasear al perro_ |
| to wash the car | washing the car | _lavar el carro_ |
| to water the grass | watering the grass | _regar el pasto_ | 


***********************************************************
| _Sala_ | Room |
| ----- | ---- |
| _baño_ | bathroom |
| _cuarto_ | bedroom | 
| _salón_ | living room |
| _lavadero_ | laundry room |
| _cocina_ | kitchen | 
| _comedor_ | dining room |
***********************************************************
`

export default function Week02() {
	return (
		<div>
			<h1>Week 12 Lecture Notes (11/27)</h1>

			<ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} children={text} />,
			<h2>Recursos</h2>
			<p>
				<ul>
					<li><a href="https://www.ingles.com/guia/el-presente-progresivo">El presente progresivo</a></li>
				</ul>
			</p>
		</div>
	)
}
